import React, { useEffect, useState } from "react";
import "./style.scss";
import { SearchSku } from "../common/SearchSku";
import useStableCallback from "../common/CustomHook/useStableCallback";
import restUtils from "../../utils/restUtils";
import { CLAIMS_BASE_API } from "../../urlConstants";
import { useSelector } from "react-redux";
import { useImmer } from "use-immer";
import CustomTable from "../CustomTable";
import { Loader, Modal, ModalContent } from "semantic-ui-react";
import {
  pendingItemTableRfa,
  submitConfrimationTable,
} from "../common/TableConstant";
import history from "../../history";
import _ from "lodash";
import { EditPolicyA } from "./Recalculate";
import utilities from "../../utils/utilities";

const ConfirmationPage = () => {
  const { storeId, userName, firstName, lastName } = useSelector(
    (state) => state.SessionReducer,
  );
  const [openSearch, setOpenSearch] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useImmer({
    status: false,
    msg: "",
    autoShipDocument: false,
    refresh: false,
    linecount:0,
    errorMessage:" ",
  });
  const closeSearchPopUp = useStableCallback(() => {
    setOpenSearch(false);
  }, [openSearch]);

  const [submitClaimData, setSubmitClaimsData] = useImmer({
    showPoup: false,
    rfaData: [],
    policyaData: [],
    tableData: [],
  });
  const [showDeletePopUp, setShowDeletePopup] = useImmer({
    show: false,
    rowId: null,
  });
  const [noData, setNoData] = useState(false);
  const [pendingItem, setPendingItem] = useState(null);
  const [editPolicyA, setEditPloicyA] = useImmer({
    showPopup: false,
    data: [],
  });
  //const [ recalculateQty, setRecalculateQty] = useImmer([])
  //const [ claim, setClaim] = useState([])
  const handleSubmitClaim = (value) => {
    let requestPayLoad = pendingItem.rfa.map((item) => {
      return {
        storeNumber: storeId,
        invoiceNumberRDC: item.invoiceNumberRDC,
        itemNumberRlyBk: item.itemNumberRlyBk,
        reason: item.reason,
        reasonId: item.reasonId.toString(),
        pack: item.pack,
        qty: item.qty,
        comments: item.comments,
        rowId: item.rowId,
        claimNumber: item.claimNumber,
        delFlag: item.delFlag,
        poNumber: item.poNumber,
        subReason: item.subReason,
        subReasonId: item.subReasonId,
        shipmentType: item.shipmentType,
        claimType: item.claimType,
        claimToGet: value,
        claimItemSessionId: item.claimItemSessionId,
        createdBy: `${firstName} ${lastName} (${userName.substr(1)})`,
      };
    });
    setIsLoading(true);
    const rfaPromise =
      pendingItem.rfa.length > 0
        ? restUtils.postDataWithBearer(
            `${CLAIMS_BASE_API}/${storeId}/SubmitRFAClaims`,
            requestPayLoad,
          )
        : Promise.resolve([]);
    const policyaPromise =
      pendingItem.policya.length > 0
        ? restUtils.postDataWithBearer(
            `${CLAIMS_BASE_API}/${storeId}/SubmitPolicyAClaim?claimNumber=${pendingItem.policya[0]?.claim_Nbr}&ClaimToGet=${value}&CreatedBy=${firstName} ${lastName} (${userName.substr(1)})`,
          )
        : Promise.resolve([]);
    Promise.all([rfaPromise, policyaPromise])
      .then((res) => {
        const [rfaData, policyAdata] = res;
        let rfaTable =
          !_.isEmpty(rfaData) && rfaData?.data?.rfaClaimConfirmDetails
            ? rfaData?.data?.rfaClaimConfirmDetails
            : [];
        let policyaTable =
          !_.isEmpty(policyAdata) &&
          policyAdata?.data?.submittedPolicyAClaimsResponse
            ?.claimItemDetailsList
            ? policyAdata?.data?.submittedPolicyAClaimsResponse
                ?.claimItemDetailsList
            : [];
        if (!_.isEmpty(rfaData) && rfaData?.data[0]?.apiError === "true") {
          const lineCounts = rfaData.data
                .filter((item) => item.apiError === "true") 
                .map((item) => {
                  const match = item.errorMessage.match(/LineNumber_(\d+)/);
                  return match ? parseInt(match[1]) : null;
              });
          setIsError((draft) => {
            draft.status = true;
            draft.msg = rfaData.data[0]?.errorMessage;
            draft.autoShipDocument = rfaData.data[0]?.autoShipDocument;
            draft.linecount = lineCounts; 
          });
        }
        setSubmitClaimsData((draft) => {
          draft.showPoup = true;
          draft.rfaData = !_.isEmpty(rfaData) ? rfaData?.data : [];
          draft.refresh =
            !_.isEmpty(rfaData) && rfaData?.data[0]?.apiError === "true"
              ? false
              : true;
          draft.policyaData = !_.isEmpty(policyAdata) ? policyAdata?.data : [];
          draft.tableData = [...rfaTable, ...policyaTable];
        });
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
        getPendingData();
        utilities.showToast("Error", true);
      });
  };
  const getPendingData = useStableCallback(() => {
    setIsLoading(true);
    setSubmitClaimsData((draft) => {
      draft.refresh = false;
    });
    restUtils
      .getDataWithBearer(`${CLAIMS_BASE_API}/${storeId}/GetPendingClaimItems`)
      .then((res) => {
        setIsLoading(false);
        if (_.isEmpty(res.data)) {
          setNoData(true);
          return;
        }
        /* const policya = res.data.policya
            let qty = []
            qty = policya.map(element => {
                let  key = element.item_Type === 'Stock' ?'item_Nbr': 'model_Nbr' 
                return {
                    'itemNbrOrModel' : element[key],
                    'qty': element.quantity
                }
            });*/
        setPendingItem(res?.data);
        // setRecalculateQty(qty)
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
        utilities.showToast("Error", true);
      });
  }, [storeId]);
  useEffect(() => {
    getPendingData();
  }, [storeId, getPendingData]);

  const handleEdit = (item) => {
    history.push({
      pathname: "/createClamis",
      state: {
        itemNumber: item.itemNumberRlyBk,
        data: item,
        isEditForm: true,
      },
    });
  };
  const handleDelete = (item) => {
    setShowDeletePopup((draft) => {
      draft.show = true;
      draft.rowId = item.rowId;
      draft.item = item;
    });
  };
  function yesDelete() {
    const { item } = showDeletePopUp;
    setIsLoading(true);
    if (item.item_Type === "Stock" || item.item_Type === "DS") {
      let val = item.item_Type === "Stock" ? item.item_Nbr : item.model_Nbr;
      restUtils
        .postDataWithBearer(
          `${CLAIMS_BASE_API}/${storeId}/RemovePolicyAPendingClaimItem?claimNumber=${item.claim_Nbr}&itemOrModelNbr=${val}&itemType=${item.item_Type}&claimItemId=${item.claim_Item_Id}`,
        )
        .then(() => {
          setIsLoading(false);
          setShowDeletePopup((draft) => {
            draft.show = false;
            draft.rowId = null;
            draft.item = null;
          });
          getPendingData();
        })
        .catch((err) => {
          console.log(err);
          utilities.showToast("Error", true);
          setIsLoading(false);
        });
      return;
    }
    restUtils
      .postDataWithBearer(
        `${CLAIMS_BASE_API}/${storeId}/RemovePendingClaimItem?claimItemSessionId=${showDeletePopUp.rowId}`,
      )
      .then(() => {
        setIsLoading(false);
        setShowDeletePopup((draft) => {
          draft.show = false;
          draft.rowId = null;
        });
        getPendingData();
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
        utilities.showToast("Error", true);
      });
  }
  function noDelete() {
    setShowDeletePopup((draft) => {
      draft.show = false;
      draft.rowId = null;
    });
  }
  function closePoup() {
    setSubmitClaimsData((draft) => {
      draft.showPoup = false;
      draft.rfaData = [];
      draft.policyaData = [];
    });
    setIsError((draft) => {
      draft.status = false;
      draft.msg = "";
      draft.autoShipDocument = false;
      draft.refresh = false;
    });
    getPendingData();
    /* if(submitClaimData.refresh) {
            getPendingData()
        }*/
  }
  /* const onChangeRecalculateQty = (e,data) =>{
        const { value, claim, itemNbrOrModel} = data
        console.log(value)
        if (value !== '') {
            const regularExpression = /^\d+$/;
              if (!regularExpression.test(value)) {
                return false;
              }
            if (value > 999 ) {
              utilities.showToast(`Quantity range should be between 1 - 999`,true);
              return false;
            }
          }
          let updateQty = []
          updateQty = recalculateQty.map(ele=>{
             if(ele.itemNbrOrModel === itemNbrOrModel) {
                return {
                    ...ele,
                    qty:value
                }
             }
             return ele
          })
          setRecalculateQty(updateQty)
          //setClaim(claim)
     }*/
  /*const recalculate = () =>{
        if(_.isEmpty(claim)) {
            return
        }
        let value = claim.item_Type === 'Stock' ? claim.item_Nbr : claim.model_Nbr;
        let quantity = recalculateQty.find(ele => ele.itemNbrOrModel === value)
         if(parseInt(quantity.qty) === 0 || _.isEmpty(quantity.qty) ) {
            utilities.showToast(`You cannot enter a quantity less than 0.`,true);
            return
         }
         let requestPayLoad = {
            "claimNumber": claim.claim_Nbr,
            "itemOrModelNbr": claim.item_Type === 'Stock' ? claim.item_Nbr.toString() : claim.model_Nbr.toString(),
            "quantity": quantity.qty,
            "claimItemId": claim.claim_Item_Id,
            "updatedBy": userName,
            "imageObjects": null,
            "images":null
          }
          restUtils.postDataWithBearer(`${CLAIMS_BASE_API}/${storeId}/RecalculateAndUpdatePolicyAClaimItem`, requestPayLoad).then(res=>{
            console.log(res)
            utilities.showToast('Updated Recalulate Qty')
          }).catch(err=>{
            console.log(err)
            utilities.showToast('Error', true)
          })
     }*/
  const recalculate = (claim) => {
    if (_.isEmpty(claim)) {
      return;
    }
    setEditPloicyA((draft) => {
      draft.showPopup = true;
      draft.data = claim;
    });
  };
  function editClosePoup() {
    setEditPloicyA((draft) => {
      draft.showPopup = false;
      draft.data = {};
    });
    getPendingData();
  }
  return (
    <>
      {isLoading && (
        <div className="commonLoader">
          <Loader active></Loader>
        </div>
      )}
      <div className="confirmationPage">
        <h3>Claim Confirmation</h3>
        <div>
          <p>
            Do you want us to reorder this merchandise for your next delivery?
          </p>
          <div>
            <button
              className="ui button redBtn"
              disabled={noData ? true : false}
              onClick={() => handleSubmitClaim("Please Reorder")}
            >
              Yes, Please reorder
            </button>
            <button
              className="ui button redBtn"
              disabled={noData ? true : false}
              onClick={() => handleSubmitClaim("Want Credit")}
            >
              No, I want credit
            </button>
            <button
              className="ui button redBtn"
              onClick={() => {
                setOpenSearch(true);
              }}
            >
              Add another Item
            </button>
          </div>
        </div>
        {openSearch && <SearchSku closePoup={closeSearchPopUp} />}
        <div className="pendingItemWrapper">
          <h3>Pending Items</h3>

          {!noData && pendingItem && (
            <div>
              <h4>Claims</h4>
              <CustomTable
                product={[...pendingItem?.rfa, ...pendingItem?.policya]}
                tableType="pendingItemRfaTable"
                tableContent={pendingItemTableRfa}
                handleEdit={handleEdit}
                handleDelete={handleDelete}
                recalculate={recalculate}
                linecount={isError.linecount} 
              />
            </div>
          )}
          {noData && <p className="noData">No Item to Display</p>}
          <div></div>
        </div>
      </div>
      {submitClaimData.showPoup && (
        <Modal
          className="submitConfirmationPopup"
          open={submitClaimData.showPoup}
        >
          <ModalContent>
            <p className="closeConfirmatiomPopup">
              <span onClick={closePoup}>
                <i className="fas fa-times"></i>
              </span>
            </p>

            <CustomTable
              product={submitClaimData?.tableData}
              tableType="submitClaimConfirmation"
              heading={"Claim Submited Successfully"}
              tableContent={submitConfrimationTable}
              rfaData={submitClaimData.rfaData}
              policyAdata={submitClaimData.policyaData}
              isError={isError}
            />
          </ModalContent>
        </Modal>
      )}
      {showDeletePopUp.show && (
        <Modal className="deleteItemPopup" open={showDeletePopUp.show}>
          <ModalContent>
            <h4>Are you sure delete the item ?</h4>
            <div>
              <button className="ui button" onClick={noDelete}>
                No
              </button>
              <button className="ui button redBtn" onClick={yesDelete}>
                Yes
              </button>
            </div>
          </ModalContent>
        </Modal>
      )}
      {editPolicyA.showPopup && (
        <Modal className="editPloicyApopup" open={editPolicyA.showPopup}>
          <ModalContent>
            <span className="closeSearchPoup" onClick={() => editClosePoup()}>
              <i className="fas fa-times"></i>
            </span>
            <h4>Edit Qty and Images</h4>
            <div>
              <EditPolicyA
                editData={editPolicyA.data}
                editClosePoup={editClosePoup}
              />
            </div>
          </ModalContent>
        </Modal>
      )}
    </>
  );
};

export default ConfirmationPage;
